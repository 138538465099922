import React, {Component} from 'react';

import './ReasonWhyUs.css'
import expertIcon from '../../assets/icons/expert.png'
import privacyIcon from '../../assets/icons/privacy.png'
import deliveryIcon from '../../assets/icons/delivery.png'
import loveIcon from '../../assets/icons/love.png'


class ReasonWhyUs extends Component {

    icons = {
        expert: expertIcon,
        privacy: privacyIcon,
        delivery: deliveryIcon,
        love: loveIcon
    }

    render(){
        let classArray = [this.props.animate? 'animate': null, 'first']
        return(
        <div className='why_wrapper'>
            <div className={classArray.join(" ")}>
                <img src={this.icons[this.props.iconType]} alt='Laser cut design icons' />
            </div>
            <div className="second">
                <h3>{this.props.messageTitle}</h3>
                <h4>{this.props.message}</h4>
            </div>
        </div>
    )
    }
    
}

export default ReasonWhyUs;