import React from 'react';

import './OurWork.css'

const ourWork = (props) => {

    return(
        <div className='ourWork_wrapper '>
            <div className='ourWork_image'>
                <img className= 'slide_in' alt='' src={props.image} />
            </div>
            < div className='ourWork_text'>
                <h2>{props.message}</h2>
                <h3>Learn more</h3>
            </div>
            <div className="at_hover">
                <h3>Learn More</h3>
            </div>
        </div>
    )
}

export default ourWork