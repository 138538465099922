import React from 'react'

import './SocialMedia.css';

const socialMedia = (props) => {

    let style={
        width:'50px',
        height: '50px',
        display: 'inline-block',
        borderRadius: '50%',
        backgroundImage: "url("+props.icon+")",
        backgroundSize: 'contain',
        margin: '8px'
    }

    return (
        <span className='social_span'>
            <a href={props.link} target="_blank" rel="noopener noreferrer" ><div style={style}></div></a>
        </span>
    )
}

export default socialMedia