import React from "react";
import "./MoreFinishedProjects.css";
import Spinner from "../../../components/Spinner/Spinner";

const moreFinishedProjects = (props) => {
  let imageData = props.moreWwdData[0];
  let videoData = props.moreWwdData[1];
  let extraImageData =
    props.moreWwdData[2] === undefined ? [] : [props.moreWwdData[2]];

  return (
    <div className="MoreFinishedProjects">
      <div className="texts">
        <h1>{props.title}</h1>
        <p>{props.openingText}</p>
      </div>

      <div className="sample">
        <table rules="groups">
          <thead>
            <tr>
              <th>Image</th>
              <th>Estimated Cost</th>
              <th>Delete</th>
            </tr>
          </thead>

          <tbody>
            {imageData?.map((data) => {
              return (
                <tr key={data.id} className="table_row">
                  <td>
                    <img alt="" src={data.image_url} width="100px" />
                  </td>
                  <td>{data.estimated_cost}</td>
                  <td>
                    {props.loading ? (
                      data.id === props.currentId ? (
                        <Spinner
                          message={"deleting"}
                          spinnerSize={30}
                          textSize={13}
                        />
                      ) : (
                        <button
                          onClick={props.handleDeleteMore(
                            data.id,
                            "child",
                            "finishedProject"
                          )}
                        >
                          Delete
                        </button>
                      )
                    ) : (
                      <button
                        onClick={props.handleDeleteMore(
                          data.id,
                          "child",
                          "finishedProject"
                        )}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}

            {extraImageData?.map((data) => {
              return (
                <tr key={data.id} className="table_row">
                  <td>
                    <img alt="" src={data.image_url} width="100px" />
                  </td>
                  <td>{data.estimated_cost}</td>
                  <td>
                    {props.loading ? (
                      data.id === props.currentId ? (
                        <Spinner
                          message={"deleting"}
                          spinnerSize={30}
                          textSize={13}
                        />
                      ) : (
                        <button
                          onClick={props.handleDeleteMore(
                            data.id,
                            "child",
                            "finishedProject"
                          )}
                        >
                          Delete
                        </button>
                      )
                    ) : (
                      <button
                        onClick={props.handleDeleteMore(
                          data.id,
                          "child",
                          "finishedProject"
                        )}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}

            {videoData?.map((data) => {
              return (
                <tr key={data.id} className="table_row">
                  <td>
                    <iframe
                      id={data?.id}
                      title={"Title-"+data?.id}
                      width="140"
                      height="105"
                      src={data.video_url}
                    ></iframe>
                  </td>
                  <td></td>
                  <td>
                    {props.loading ? (
                      data.id === props.currentId ? (
                        <Spinner
                          message={"deleting"}
                          spinnerSize={30}
                          textSize={13}
                        />
                      ) : (
                        <button
                          onClick={props.handleDeleteMore(
                            data.id,
                            "child",
                            "finishedPojectVideo"
                          )}
                        >
                          Delete
                        </button>
                      )
                    ) : (
                      <button
                        onClick={props.handleDeleteMore(
                          data.id,
                          "child",
                          "finishedPojectVideo"
                        )}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <form>
        <div className="image">
          <div>
            <h3>Upload image here</h3>
          </div>
          <div className="controls ">
            <input
              className="file_input"
              type="file"
              accept="image/*"
              onChange={props.fileSelectedHandler}
              required
            />
            {props.loading ? (
              <Spinner message="Uploading" spinnerSize={50} messageSize={16} />
            ) : (
              <input
                className="submit"
                type="submit"
                value="Upload"
                onClick={props.handleUploadMore("finishedProjects")}
              />
            )}
          </div>
        </div>
        <div className="video">
          <div>
            <h3>Upload Video Url Here</h3>
          </div>
          <div className="controls">
            <input
              className="textfield"
              type="text"
              placeholder="Youtube url"
              onChange={props.textChangedHandler}
              required
            />
            {props.loading ? (
              <Spinner message="Uploading" spinnerSize={50} messageSize={16} />
            ) : (
              <input
                className="submit"
                type="submit"
                value="Upload"
                onClick={props.handleUploadVideoUrl}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default moreFinishedProjects;
