 import React, { Component} from 'react';
 import { Route, Switch,BrowserRouter} from 'react-router-dom';
 import './App.css';
import Layout from './hoc/Layout/Layout';
import HomePage from './containers/HomePage/Homepage';
import Admin from './containers/Admin/Admin';
import ViewMore from './containers/ViewMore/ViewMore'
import Request from './containers/Request/Request'
import Products from './containers/Products/Products'
import ContactUS from './containers/ContactUS/ContactUS';
import CompletedWorks from './containers/CompletedWorks/CompletedWorks'

import axios from 'axios';
import { baseUrl } from "./constants";

axios.defaults.baseURL = baseUrl
axios.defaults.headers.common["Authorization"] = "token"




class App extends Component {
 
  render() {

    return (
      <div className='app_top_wrapper'>
      <BrowserRouter>
        <Layout>
            <Switch>
             <Route path="/" exact component={HomePage} />
              <Route path="/chiomasom" exact component={Admin} />
              <Route path="/products" exact component={Products} />
              <Route path="/completed-projects" exact component={CompletedWorks} />
              <Route path="/contact-us" exact component={ContactUS} />
              <Route path="/building:id/:type" component={ViewMore}/>
              <Route path="/request/:type/:id" component={Request}/>
              {/* <Route path="" component={Admin} /> */}
            </Switch>
        </Layout> 
      </BrowserRouter>
      </div>
      
    );
  }
}

export default App;
