import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems'
import Logo from '../Logo/Logo'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
import whatsapImage from '../../../assets/icons/whatsapp.png'
import emailImage from '../../../assets/icons/email2.png'
import './Toolbar.css'


const toolbar = (props) => {
    return (
        <div className='toolbar_wrapper'>
              <div className='sec_nav'>
                <a target='_blank' rel="noopener noreferrer" href= "https://api.whatsapp.com/send?phone=2347065071299&text=I'd like to request your products and services"><img src={whatsapImage} alt='' /><h3> +2347065071299</h3></a>
                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=2348137796116&text=I'd like to request your products and services"><img src={whatsapImage} alt='' /><h3> +23481377966116</h3></a>
                <a target='_blank' rel="noopener noreferrer" href="mailto:info@paradisotechs.com?subject=Request for Quotation"><h3><img src={emailImage} alt='' /> info@paradisotechs.com</h3></a>
              </div>
              <div className='toolbar'>
                  <Logo openMenu={props.openMenu}/>
                  <div className='inner-nav'>
                    <div className='whatsapp'>
                        <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=2347065071299&text=I'd like to request your products and services"><img src={whatsapImage} alt='whatsapImage'/> <h3> +2347065071299</h3> </a>
                        <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=2348137796116&text=I'd like to request your products and services"><img src={whatsapImage} alt='whatsapImage'/> <h3> +2348137796116</h3> </a>
                    </div>
                    <div className='email'>
                      <a target='_blank' rel="noopener noreferrer" href="mailto:info@paradisotechs.com?subject=Request for Quotation"><img src={emailImage} alt='emimg' /> <h3>info@paradisotechs.com</h3></a>
                      
                    </div>
                    
                  </div>   
                  <HamburgerMenu
                    menuClicked={props.menuClicked}
                    rotate={props.openMenu} />     
                  <nav className='desktop_view'>
                    <NavigationItems  />   
                  </nav>
              </div>
            

               
        </div>
    )
}

export default toolbar;