import React from "react";
import { Link } from "react-router-dom";
import Lazyload from "react-lazy-load";
import { Element } from "react-scroll";

import OurWork from "../../components/OurWork/OurWork";
import "./WhatWeDo.css";

const whatWeDo = (props) => {
  let laser = props.laser === undefined ? [] : props.laser;
  let stainless = props.stainless === undefined ? [] : props.stainless;
  let wrought = props.wrought === undefined ? [] : props.wrought;
  let finished = props.finished === undefined ? [] : props.finished;
  return (
    <div className="whatWeDo_wrapper">
      <div className="ourWork_title">
        <h2>What we do</h2>
      </div>
      <div className="ourWork_message">
        <h2>Laser Cut Building Additions:</h2>
        <h3>
          The possibilities are endless, We create designs of your choice and
          manufacture them beautifully
        </h3>
      </div>
      <div className="ourWork_images">
        {laser.map((data) => {
          return (
            <Link
              to={`/building${data.id}/laser-cut-building-additions`}
              key={data.id}
            >
              <Lazyload debounce={false} offsetVertival={100}>
                <OurWork image={data.image_url} message={data.title} />
              </Lazyload>
            </Link>
          );
        })}
      </div>

      <div className="ourWork_message">
        <h2 className="others">Stainless Rails Installations:</h2>
        <h3>We also combine stainless steels with custom laser cut designs</h3>
      </div>
      <div className="ourWork_images">
        {stainless.map((data) => {
          return (
            <Link
              to={`/building${data.id}/stainless-rail-designs`}
              key={data.id}
            >
              <Lazyload debounce={false} offsetVertival={100}>
                <OurWork image={data.image_url} message={data.title} />
              </Lazyload>
            </Link>
          );
        })}
      </div>

      <div className="ourWork_message">
        <h2 className="others">Wrought Iron Rail designs and Installation.</h2>
        <h3>
          Depending on your choice, we use our fine laser cut designs to give
          your wrought iron rails and gates the touch of elegance. Here are a
          few of our samples:
        </h3>
      </div>
      <div className="ourWork_images">
        {wrought.map((data) => {
          return (
            <Link to={`/building${data.id}/wrought-iron-designs`} key={data.id}>
              <Lazyload debounce={false} offsetVertival={100}>
                <OurWork image={data.image_url} message={data.title} />
              </Lazyload>
            </Link>
          );
        })}
      </div>

      <Element id="completed-projects" name="completed-projects">
        <div className="ourWork_message">
          <h2 className="others">We've completed a couple of projects:</h2>
          <h3>Here are a few of such elegant projects</h3>
        </div>
        <div className="ourWork_images">
          {finished.map((data) => {
            return (
              <Link to={`/building${data.id}/finished-products`} key={data.id}>
                <Lazyload key={data.id} debounce={false} offsetVertival={200}>
                  <OurWork
                    key={data.id}
                    image={data.image_url}
                    message={data.title}
                  />
                </Lazyload>
              </Link>
            );
          })}
        </div>
      </Element>
    </div>
  );
};

export default whatWeDo;
