import React from "react";
import { Link } from "react-router-dom";

import logoImage from "../../../assets/images/logo 3.png";
import "./Logo.css";

const logo = (props) => {
  let logoClass =  ["logo"];
  return (
    <div className={logoClass}>
      <Link to="/">
        <img alt="logo" src={logoImage} />
      </Link>
    </div>
  );
};

export default logo;
