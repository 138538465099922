import React,{Component} from 'react';

import ReasonWhyUs from '../../components/ReasonWhyUs/ReasonWhyUs';
import './WhyUs.css'

class WhyUs extends Component {

    constructor(props){
        super(props);
        this.competencyRef = React.createRef();
        this.loveRef = React.createRef();
        this.privacyRef = React.createRef();
        this.deliveryRef = React.createRef();

        this.competencyClasses = ['whyus_inner briefcase'];
        this.loveClasses = ['whyus_inner heart'];
        this.privacyClasses = ['whyus_inner user_shield']
        this.deliveryClasses =['whyus_inner delivery']
    }

    state={
        competencyPosition: null,
        lovePosition: null,
        privacyPosition: null,
        deliveryPosition: null,
        height: null
        
    }

    componentDidMount(){
       this.setState({competencyPosition: this.competencyRef.current.offsetTop,
                      lovePosition: this.loveRef.current.offsetTop,
                      privacyPosition: this.privacyRef.current.offsetTop,
                      deliveryPosition: this.deliveryRef.current.offsetTop,
                      height: this.privacyRef.current,

                      });

    
    }


     loveMessage = 'We love how creative you are with your imaginations. Moreso, we love how we beat your imaginations to deliver your dreams.';
     privacyMessage=' Your privacy is safe with us, Our policy allows for a secured privacy';
     competencyMessage='Our engineers are specially trained to deliver excellence or nothing. And everytime, we deliver excellence.';
     deliveryMessage="Our ever hard working team delivers your orders speedily; Our schedule stands no matter what falls"

     render(){

        let showLoveAt, showCompetencyAt, showPrivacyAt, showDeliveryAt;
       let scrollPos = this.props.scrollPosition
       if(this.state.height !== null){
            showLoveAt = Number(this.state.lovePosition) + Number(this.state.height.clientHeight);
            showPrivacyAt = Number(this.state.privacyPosition) + (Number(this.state.height.clientHeight));
            showCompetencyAt = Number(this.state.competencyPosition) + (Number(this.state.height.clientHeight));
            showDeliveryAt = Number(this.state.deliveryPosition)+(Number(this.state.height.clientHeight))
       }

       if(scrollPos > showLoveAt){
           
        if(!  this.loveClasses.forEach(element => {
            if(element ==="show") return true
        }))
        this.loveClasses.push('show');
        }

        if(scrollPos > showCompetencyAt){
            
            if(!  this.competencyClasses.forEach(element => {
                if(element ==="show") return true
            }))
            this.competencyClasses.push('show');
        }

        if(scrollPos > showPrivacyAt){
            
            if(!  this.privacyClasses.forEach(element => {
                if(element ==="show") return true
            }))
            this.privacyClasses.push('show');
        }
        if(scrollPos > showDeliveryAt){
            
            if(!  this.deliveryClasses.forEach(element => {
                if(element ==="show") return true
            }))
            this.deliveryClasses.push('show');
        }

         return (
             <div className='Whyuswrap'>
               <div className='wrap'>
                <div className='title title_why_us'>
                    <h2>Why Us</h2>
                </div>
                <div className='whyus'>
                    <div className={this.competencyClasses.join(' ')} ref={this.competencyRef }>
                        <ReasonWhyUs  iconType='expert' color='rgb(31, 13, 109)' messageTitle='Competent Engineers' message={this.competencyMessage} /> 
                    </div>

                    <div className={this.deliveryClasses.join(' ')} ref={this.deliveryRef }>
                        <ReasonWhyUs  iconType='delivery' color='rgb(31, 13, 109)' messageTitle='Quick Delivery' message={this.deliveryMessage} /> 
                    </div>

                    <div className={this.privacyClasses.join(' ')} ref={this.privacyRef}> 
                        <ReasonWhyUs  iconType='privacy' color='rgb(21, 5, 80)' messageTitle='Privacy Guaranted' message={this.privacyMessage} />  
                    </div>

                    <div className={this.loveClasses.join(' ')} ref={this.loveRef}>
                        <ReasonWhyUs animate iconType='love' color='rgb(228, 61, 139)' messageTitle='We Love our Clients' message={this.loveMessage} />
                    </div>
                        
                </div>
                
              </div>
                <div className='svgdiv'>
                    <svg className="Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1450 100"><path  fillOpacity="1" d="M0,0L48,16C96,32,192,64,288,74.7C384,85,480,75,576,74.7C672,75,768,85,864,74.7C960,64,1056,32,1152,37.3C1248,43,1344,85,1392,106.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                </div>   
             </div>
           
       
    )
     }
    
}

export default WhyUs;