import React from 'react';

import './Input.css';

const input = (props) => {
  let inputElement = null;
  let elementClasses = ['input_element'];
  let textAreaClass = ['textarea']

  if(props.invalid && props.shouldValidate && props.touched){
      elementClasses.push('input_invalid');
      textAreaClass.push('input_invalid')
  }
  


  switch(props.elementType){
    case('input'):
        inputElement = <input
            className={elementClasses.join(' ')} {...props.elementConfig} 
            value={props.value} onChange={props.changed} />
        break;
    
    case('textarea'):
        inputElement= <textarea
            className={textAreaClass.join(' ')} {...props.elementConfig} 
            value={props.value} onChange={props.changed} />
        break;

    case('select'):
        inputElement = <select 
            className={elementClasses.join(' ')}  
            value={props.value} onChange={props.changed}>
            {props.elementConfig.options.map(opt => (
                <option key={opt.value} value={opt.value}>
                    {opt.displayValue}
                </option>
            ))}
            </select>
        break;

    default: inputElement = <input
                className={elementClasses.join(' ')} {...props.elementConfig} 
                value={props.value} onChange={props.changed} />

  }

  return (
      <div className='input_wrapper' >
        <label className='label'>{props.label}</label>
        {inputElement}
      </div>
  )
}

export default input;