import React, { Component } from "react";
import axios from "axios";
import "./Admin.css";
import AdminWWA from "../../components/AdminWWA/AdminWWA";
import AdminWWD from "../../components/AdminWWD/AdminWWD";
import MoreWwd from "../../components/AdminWWD/MoreWwd/MoreWwd";
import Stainless from "../../components/AdminStainless/AdminStainless";
import utilities from "../../Utilities/utilities";
import Spinner from "../../components/Spinner/Spinner";
import Morestainless from "../../components/AdminStainless/MoreStainless/MoreStainless";
import Wrought from "../../components/AdminWrought/AdminWrought";
import MoreWrought from "../../components/AdminWrought/MoreWrought/MoreWrought";
import AdminFinishedProjects from "../../components/AdminFinishedProjects/AdminFinishedProjects";
import MoreFinishedProjects from "../../components/AdminFinishedProjects/MoreFinishedProjects/MoreFinishedProjects";

class Admin extends Component {
  state = {
    imageFile: null,
    text: null,
    wwaImageUrl: null,
    wwaText: null,
    wwdData: [],
    showEdit: false,
    imageChanged: false,
    editId: null,
    editTitle: "",
    oldFileName: null,
    editIndex: null,
    editImageUrl: "",
    openingText: "",
    title: "",
    moreWwdData: [],
    route: "wwa",
    cost: 0,
    currentId: 0,
    loading: false,
    smallLoading: false,
    activeButton: 0,
  };

  production = false;
  baseLink = this.production
    ? "https://node.paradisotechs.com"
    : "http://localhost:3001";

  handleAddMore = (id, product) => (event) => {
    console.log("WE are about to post more stainless");
    const destination = {
      laser: "getwwdmore",
      stainless: "getstainlessmore",
      wrought: "getwroughtmore",
      finishedProjects: "getfinishedProjectsmore",
    };

    const route = {
      laser: "morewwd",
      stainless: "morestainless",
      wrought: "morewrought",
      finishedProjects: "morefinishedProjects",
    };

    //lets extract the details from it and update our state
    this.state.wwdData.forEach((data) => {
      if (data.id === id) {
        this.setState({
          loading: true,
          openingText: data.opening_text,
          title: data.title,
          currentId: id,
        });
      }
    });
    axios
      .get("/api/admin/" + destination[product] + "/" + id)
      .then((res) => {
        if (res.data[0]) {
          this.setState({
            moreWwdData: res.data,
            route: route[product],
            loading: false,
          });
        } else {
          this.setState({
            route: route[product],
            moreWwdData: [],
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log("In the catch block: ", err);
        console.log("there is an error: " + err);
        this.setState({ route: route[product], loading: false });
      });
  };

  handleUploadMore = (product) => (event) => {
    event.preventDefault();
    const destination = {
      laser: "addwwdmore",
      stainless: "addstainlessmore",
      wrought: "addwroughtmore",
      finishedProjects: "addfinishedproductmore",
    };
    console.log(product);
    let originalFile = this.state.imageFile;
    utilities
      .resizeImage({ file: this.state.imageFile, maxSize: 350 })
      .then((resizedImage) => {
        const fd = new FormData();
        fd.append("file", resizedImage);
        fd.append("originalFile", originalFile);
        fd.append("cost", this.state.cost);
        fd.append("id", this.state.currentId);
        axios
          .post("/api/admin/" + destination[product], fd)
          .then((res) => {
            let data = [...this.state.moreWwdData];
            data.push(res.data[0]);
            this.setState({ moreWwdData: data });
          })
          .catch((err) => console.log("we couldn't post it: ", err));
      });
  };

  handleEdit = (id, product) => (event) => {
    let title, imageUrl, index, openingText;

    let datas = [...this.state.wwdData];
    datas.forEach((datum, i) => {
      if (datum.id === id) {
        title = datum.title;
        imageUrl = datum.image_url;
        openingText = datum.opening_text;
        index = i;
      }
    });
    let oldImageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
    this.setState({ oldFileName: oldImageName, editImageUrl: imageUrl });
    this.setState({
      editTitle: title,
      editId: id,
      showEdit: true,
      editIndex: index,
      openingText: openingText,
    });
  };

  editTextHandler = (event) => {
    this.setState({ editTitle: event.target.value });
  };

  editFileHandler = (event) => {
    this.setState({ imageChanged: true, imageFile: event.target.files[0] });
  };

  editCancelHandler = () => {
    this.setState({ showEdit: false, imageChanged: false, fileName: "" });
  };

  editUpdateHandler = (product) => (event) => {
    console.log("We are at edit handler");
    const destination = {
      laser: "editwwd",
      stainless: "editstainless",
      wrought: "editwrought",
      finishedProduct: "editfinishedProduct",
    };

    const destination2 = {
      laser: "editwwdnofile",
      stainless: "editstainlessnofile",
      wrought: "editwroughtnofile",
      finishedProduct: "editFinishedProjectsnofile",
    };

    if (this.state.imageChanged) {
      if (this.state.imageFile) {
        utilities
          .resizeImage({ file: this.state.imageFile, maxSize: 350 })
          .then((resizedImage) => {
            const fd = new FormData();
            fd.append("file", resizedImage);
            fd.append("id", this.state.editId);
            fd.append("title", this.state.editTitle);
            fd.append("openingText", this.state.openingText);
            fd.append("fileName", this.state.oldFileName);
            axios
              .put("/api/admin/" + destination[product], fd)
              .then((res) => {
                console.log(res);
                let copiedData = [...this.state.wwdData];
                copiedData[this.state.editIndex] = res.data[0];
                this.setState({ wwdData: copiedData });
              })
              .catch((err) => console.log(err));
          });
      }
    } else {
      const fd = new FormData();
      fd.append("id", this.state.editId);
      fd.append("title", this.state.editTitle);
      fd.append("imageUrl", this.state.editImageUrl);
      fd.append("openingText", this.state.openingText);
      axios
        .put("/api/admin/" + destination2[product], fd)
        .then((res) => {
          let copiedData = [...this.state.wwdData];
          copiedData[this.state.editIndex] = res.data[0];
          this.setState({ wwdData: copiedData });
        })
        .catch((err) => console.log(err));
    }

    this.setState({ showEdit: false, imageChanged: false, fileName: "" });
  };

  handleDelete = (id, type, product) => (event) => {
    console.log({ id, type, product });
    const destination = {
      laser: "delete",
      stainless: "delete/stainless",
      wrought: "delete/wrought",
      finishedProject: "delete/finishedprojects",
      finishedPojectVideo: "delete/finishedProject/video",
    };
    this.setState({ smallLoading: true });
    let title;
    if (type === "parent") {
      this.state.wwdData.forEach((data) => {
        if (data.id === id) {
          title = data.title;
        }
      });
    }

    if (
      window.confirm(
        "You are about to delete " + title + " and all its children"
      )
    ) {
      const parentId = this.state.currentId;
      axios
        .delete(
          "/api/admin/" +
            destination[product] +
            id +
            "/" +
            type +
            "/" +
            parentId +
            "/here"
        )
        .then((res) => {
          if (type === "parent") {
            this.setState({ wwdData: res.data, smallLoading: false });
          } else {
            this.setState({ moreWwdData: res.data, smallLoading: false });
          }
        })
        .catch(console.log);
    } else {
      this.setState({ smallLoading: false });
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({ imageFile: event.target.files[0] });
  };

  textChangeHandler = (event) => {
    this.setState({ text: event.target.value });
  };
  costChangedHandler = (event) => {
    this.setState({ cost: event.target.value });
  };

  openingTextHandler = (event) => {
    this.setState({ openingText: event.target.value });
  };

  onUploadHandler = (destination) => (event) => {
    event.preventDefault();
    this.setState({ smallLoading: true });
    utilities
      .resizeImage({ file: this.state.imageFile, maxSize: 350 })
      .then((resizedImage) => {
        const fd = new FormData();
        fd.append("file", resizedImage);
        fd.append("text", this.state.text);
        fd.append("openingText", this.state.openingText);
        axios
          .post("/api/admin/" + destination, fd)
          .then((res) => {
            switch (destination) {
              case "addwwd": {
                let data = [...this.state.wwdData];
                data.push(res.data[0]);
                this.setState({ wwdData: data, smallLoading: false });
                break;
              }
              case "addwwa": {
                this.setState({
                  smallLoading: false,
                  wwaImageUrl: res.data[0].image_url,
                  wwaText: res.data[0].text,
                });
                break;
              }
              case "addstainless": {
                let data = [...this.state.wwdData];
                data.push(res.data[0]);
                this.setState({ wwdData: data, smallLoading: false });
                break;
              }
              case "addwrought": {
                let data = [...this.state.wwdData];
                data.push(res.data[0]);
                this.setState({ wwdData: data, smallLoading: false });
                break;
              }

              default: {
                let data = [...this.state.wwdData];
                data.push(res.data[0]);
                this.setState({ wwdData: data, smallLoading: false });
              }
            }
          })
          .catch((err) => {
            console.log("Error Occured", err);
          });
      });
  };

  updateWwa = (event) => {
    this.setState({ smallLoading: true });
    let fd = new FormData();
    fd.append("text", this.state.text);
    fd.append("description", this.state.text);
    fd.append("file", this.state.imageFile);

    axios
      .put("/api/admin/addwwa", fd)
      .then((res) => {
        this.setState({
          smallLoading: false,
          wwaImageUrl: res.data[0].image_url,
          wwaText: res.data[0].text,
        });
      })
      .catch(console.log)
      .finally(() => this.setState({ smallLoading: false }));
  };

  componentDidMount() {
    this.setState({ loading: true });
    axios
      .get("/api/admin/getwwa")
      .then((res) => {
        if (res.data[0] !== undefined)
          this.setState({
            wwaImageUrl: res.data[0].image_url,
            wwaText: res.data[0].text,
            loading: false,
          });
      })
      .catch(console.log)
      .finally(this.setState({ loading: false }));
  }
  handleWwa = () => {
    this.setState({ loading: true, activeButton: 0 });
    axios.get("/api/admin/getwwa").then((res) => {
      if (res.data[0] !== undefined) {
        this.setState({
          wwaImageUrl: res.data[0].image_url,
          wwaText: res.data[0].text,
          route: "wwa",
          loading: false,
        });
      }
    });
  };

  handleWwd = () => {
    this.setState({ loading: true, activeButton: 1 });
    axios.get("/api/admin/getwwd").then((res) => {
      this.setState({ wwdData: res.data, route: "wwd", loading: false });
    });
  };

  handleStainless = () => {
    this.setState({ activeButton: 2, route: "stainless" });
    axios
      .get("/api/admin/getstainless")
      .then((res) => {
        this.setState({ wwdData: res.data, loading: false });
      })
      .catch(console.log);
  };

  handleWrought = () => {
    this.setState({ activeButton: 3, route: "wrought" });
    axios
      .get("/api/admin/getwrought")
      .then((res) => {
        this.setState({ wwdData: res.data, loading: false });
      })
      .catch(console.log);
  };

  handleFinishedProjects = () => {
    this.setState({ activeButton: 4, route: "finishedProjects" });
    axios
      .get("/api/admin/getFinishedProjects")
      .then((res) => {
        this.setState({ wwdData: res.data, loading: false });
      })
      .catch(console.log);
  };

  handleUploadVideoUrl = () => {
    this.setState({ smallLoading: true });
    const fd = new FormData();
    fd.append("videoUrl", this.state.text);
    fd.append("parentId", this.state.currentId);
    axios
      .post("/api/admin/postvideourl", fd)
      .then((res) => {
        this.setState({ moreWwdData: res.data, smallLoading: false });
      })
      .catch((err) => {
        this.setState({ smallLoading: false });
      });
  };

  render() {
    return (
      <div className="Admin">
        <div className="left">
          <button
            className={this.state.activeButton === 0 ? "active" : ""}
            onClick={this.handleWwa}
          >
            Who we are
          </button>
          <button
            className={this.state.activeButton === 1 ? "active" : ""}
            onClick={this.handleWwd}
          >
            Laser Designs
          </button>
          <button
            className={this.state.activeButton === 2 ? "active" : ""}
            onClick={this.handleStainless}
          >
            Stainless Designs
          </button>
          <button
            className={this.state.activeButton === 3 ? "active" : ""}
            onClick={this.handleWrought}
          >
            {" "}
            Wrought Designs
          </button>
          <button
            className={this.state.activeButton === 4 ? "active" : ""}
            onClick={this.handleFinishedProjects}
          >
            Finished Projects
          </button>
        </div>
        <div className="right">
          {this.state.route === "wwa" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <AdminWWA
                fileSelectedHandler={this.fileSelectedHandler}
                textChangeHandler={this.textChangeHandler}
                onUploadHandler={this.updateWwa}
                wwaText={this.state.wwaText}
                wwaImageUrl={this.state.wwaImageUrl}
                loading={this.state.smallLoading}
              />
            )
          ) : this.state.route === "wwd" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <AdminWWD
                fileSelectedHandler={this.fileSelectedHandler}
                textChangeHandler={this.textChangeHandler}
                openingTextHandler={this.openingTextHandler}
                onUploadHandler={this.onUploadHandler}
                wwdData={this.state.wwdData}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                handleAddMore={this.handleAddMore}
                showEdit={this.state.showEdit}
                editCancelHandler={this.editCancelHandler}
                editUpdateHandler={this.editUpdateHandler}
                editFileHandler={this.editFileHandler}
                editTextHandler={this.editTextHandler}
                editTitle={this.state.editTitle}
                editOpeningText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.route === "stainless" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <Stainless
                fileSelectedHandler={this.fileSelectedHandler}
                textChangeHandler={this.textChangeHandler}
                openingTextHandler={this.openingTextHandler}
                onUploadHandler={this.onUploadHandler}
                wwdData={this.state.wwdData}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                handleAddMore={this.handleAddMore}
                showEdit={this.state.showEdit}
                editCancelHandler={this.editCancelHandler}
                editUpdateHandler={this.editUpdateHandler}
                editFileHandler={this.editFileHandler}
                editTextHandler={this.editTextHandler}
                editTitle={this.state.editTitle}
                editOpeningText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.route === "morestainless" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <Morestainless
                moreWwdData={this.state.moreWwdData}
                handleUploadMore={this.handleUploadMore}
                costChangedHandler={this.costChangedHandler}
                fileSelectedHandler={this.fileSelectedHandler}
                handleDeleteMore={this.handleDelete}
                title={this.state.title}
                openingText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.route === "wrought" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <Wrought
                fileSelectedHandler={this.fileSelectedHandler}
                textChangeHandler={this.textChangeHandler}
                openingTextHandler={this.openingTextHandler}
                onUploadHandler={this.onUploadHandler}
                wwdData={this.state.wwdData}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                handleAddMore={this.handleAddMore}
                showEdit={this.state.showEdit}
                editCancelHandler={this.editCancelHandler}
                editUpdateHandler={this.editUpdateHandler}
                editFileHandler={this.editFileHandler}
                editTextHandler={this.editTextHandler}
                editTitle={this.state.editTitle}
                editOpeningText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.route === "morewrought" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <MoreWrought
                moreWwdData={this.state.moreWwdData}
                handleUploadMore={this.handleUploadMore}
                costChangedHandler={this.costChangedHandler}
                fileSelectedHandler={this.fileSelectedHandler}
                handleDeleteMore={this.handleDelete}
                title={this.state.title}
                openingText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.route === "finishedProjects" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <AdminFinishedProjects
                fileSelectedHandler={this.fileSelectedHandler}
                textChangeHandler={this.textChangeHandler}
                openingTextHandler={this.openingTextHandler}
                onUploadHandler={this.onUploadHandler}
                wwdData={this.state.wwdData}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                handleAddMore={this.handleAddMore}
                showEdit={this.state.showEdit}
                editCancelHandler={this.editCancelHandler}
                editUpdateHandler={this.editUpdateHandler}
                editFileHandler={this.editFileHandler}
                editTextHandler={this.editTextHandler}
                editTitle={this.state.editTitle}
                editOpeningText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.route === "morefinishedProjects" ? (
            this.state.loading ? (
              <Spinner message="Loading" textSize={30} spinnerSize={100} />
            ) : (
              <MoreFinishedProjects
                moreWwdData={this.state.moreWwdData}
                handleUploadMore={this.handleUploadMore}
                handleUploadVideoUrl={this.handleUploadVideoUrl}
                textChangedHandler={this.textChangeHandler}
                fileSelectedHandler={this.fileSelectedHandler}
                handleDeleteMore={this.handleDelete}
                title={this.state.title}
                openingText={this.state.openingText}
                loading={this.state.smallLoading}
                currentId={this.state.currentId}
              />
            )
          ) : this.state.loading ? (
            <Spinner message="Loading" textSize={30} spinnerSize={100} />
          ) : (
            <MoreWwd
              moreWwdData={this.state.moreWwdData}
              handleUploadMore={this.handleUploadMore}
              costChangedHandler={this.costChangedHandler}
              fileSelectedHandler={this.fileSelectedHandler}
              handleDeleteMore={this.handleDelete}
              title={this.state.title}
              openingText={this.state.openingText}
              loading={this.state.smallLoading}
              currentId={this.state.currentId}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Admin;
