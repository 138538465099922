import React, { Component } from "react";
import axios from "axios";
import workImage from "../../assets/svg/paradiso-work-frame.svg";
import "./AboutUs.css";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.descriptionText = React.createRef();
    this.descriptionImage = React.createRef();

    this.textClasses = ["body", "description"];
    this.imageClasses = ["body", "working_image"];
  }

  state = {
    textPosition: null,
    imagePosition: null,
    imageHeight: null,
    textHeight: null,
    wwaText: null,
    wwaImageUrl: null,
  };

  production = false;
  baseLink = this.production
    ? "https://node.paradisotechs.com"
    : "http://localhost:3001";

  componentWillMount() {}

  componentDidMount() {
    this.setState({
      textPosition: this.descriptionText.current.offsetTop,
      imagePosition: this.descriptionImage.current.offsetTop,
      imageHeight: this.descriptionImage.current,
      textHeight: this.descriptionImage.current,
    });

    axios
      .get( "/api/admin/getwwa")
      .then((res) => {
        this.setState({
          wwaImageUrl: res.data[0].image_url,
          wwaText: res.data[0].text,
        });
      })
      .catch(console.log);
  }

  render() {
    let showImageAt, showTextAt;
    let scrollPos = this.props.scrollPosition;
    if (this.state.imageHeight !== null) {
      showImageAt =
        Number(this.state.imagePosition) +
        Number(this.state.imageHeight.clientHeight) +
        558;
      showTextAt =
        Number(this.state.textPosition) +
        Number(this.state.textHeight.clientHeight) +
        458;
    }

    //This top if statement is to prevent the page from calculating the heights when they have not fully load
    // The elements ontop
    if (showTextAt > 600) {
      if (scrollPos > showImageAt) {
        //check if the class is there before adding it again
        if (this.imageClasses.indexOf("show") === -1) {
          this.imageClasses.push("show");
        }
      }

      if (scrollPos > showTextAt) {
        if (this.textClasses.indexOf("show") === -1) {
          this.textClasses.push("show");
        }
      }
    }

    return (
      <div className="AboutUs">
        <div className="title">
          <h1> Who we are </h1>
        </div>
        <div className="aboutus_body">
          <div
            className={this.textClasses.join(" ")}
            ref={this.descriptionText}
          >
            <h2>{this.state.wwaText}</h2>
          </div>
          <div ref={this.descriptionImage}>
            <img
              className={this.imageClasses.join(" ")}
              alt="Laser Cut Design Works"
              src={workImage}
            />
          </div>
        </div>
        <div>
          <svg
            className="Svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1450 100"
          >
            <path
              fillOpacity="1"
              d="M0,0L48,16C96,32,192,64,288,74.7C384,85,480,75,576,74.7C672,75,768,85,864,74.7C960,64,1056,32,1152,37.3C1248,43,1344,85,1392,106.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    );
  }
}

export default AboutUs;
