import React from 'react';
import './MoreWwd.css';
import Spinner from '../../../components/Spinner/Spinner'


const MoreWwd = (props) => {
    
    
    return (
        <div className="MoreWwd">

            <div className='texts'>
                <h1>{props.title}</h1>
                <p>{props.openingText}</p>
            </div>

            <div className ="sample">
                <table rules="groups">
                   <thead>
                        <tr>
                            <th>Image</th>
                            <th>Estimated Cost</th>
                            <th>Delete</th> 
                        </tr>
                   </thead> 
                        
                    <tbody>
                         {props.moreWwdData.map(data =>{
                             
                            return(
                                    <tr key={data.id} className = "table_row" >
                                        <td><img alt=""  src={data.image_url} width ="100px" /></td>
                                        <td>{data.estimated_cost}</td>
                                        <td>{ props.loading? 
                                                ( data.id === props.currentId ? 
                                                    <Spinner message={'deleting'} spinnerSize={30} textSize={13} />
                                                    : 
                                                    <button   onClick = {props.handleDeleteMore(data.id,'child','laser')}>Delete</button> 
                                                    )
                                                : 
                                                <button   onClick = {props.handleDeleteMore(data.id,'child','laser')}>Delete</button> }</td>
                                    </tr>
                            )
                        })
                        } 
                    </tbody>
                   
                </table>
            </div>

            <form>
                <div>
                    <input type='number' placeholder ="Cost" onChange={props.costChangedHandler} required />
                    <input className = 'file_input' type ="file" accept ="image/*" onChange={props.fileSelectedHandler}  required/>   
                </div>
                {props.loading ? <Spinner  message='Uploading' spinnerSize={50} messageSize={16} /> : <input className='submit' type = "submit" value="Upload" onClick={props.handleUploadMore('laser')} /> }
            </form>
        </div>
    )
}

export default MoreWwd;