import React from "react";
import { RingLoader } from "react-spinners";
// import { css } from "@emotion/react";

import "./Spinner.css";

const spinner = (props) => {
  // const override = css`
  //   display: inline;
  //   margin: 0;
  //   border: 1px solid yellow;
  // `;

  return (
    <div className="Spinner11">
      <RingLoader
        className={"spinner"}
        size={props.spinnerSize}
        sizeUnit={"px"}
        color={"red"}
        loading={true}
        // css={override}
      />
      <h3 style={{ fontSize: props.textSize, color: props.color }}>
        {props.message}...
      </h3>
    </div>
  );
};

export default spinner;
