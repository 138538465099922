import React from 'react';
import {Helmet} from 'react-helmet'

class Head extends React.Component{
    render(){
        return(
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{this.props.title}</title>
                <meta name='description' content={this.props.description} />
                <meta name='tags' content={this.props.tags} />
                <meta name="author" content="Chikwado Emmanuel" />
                
            </Helmet>
        )
    }
}

export default Head