import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import './LandingPage.css';
import gateImage from '../../assets/images/gate designm.jpg';
import transparentLogo from '../../assets/images/logo_transparent.png'
import whitestairRail from '../../assets/new image/stair-railing.jpg'

class LandingPage extends Component {

    render() {

        return(
            <div className='landing_page_wrapper'>
                <ul className='Backgrounds'>
                    <li>
                        <span><div></div></span>
                        <div className="landing_first">
                            <div className="landing_text">
                                <h2>Unique and Beautiful Laser cut Building additions for your home,offices and organizations</h2>
                                <img alt='' src={whitestairRail} />
                            </div>
                            
                            <div className="landing_buttons">
                                <Link to='/products' style={{textDecoration:'none'}}>
                                  <h4>Learn More</h4>
                               </Link>
                               <Link to='/contact-us' style={{textDecoration:'none'}}>
                                    <h4>Contact Us</h4>
                               </Link>
                            </div>
                        </div>
                    </li>


                    <li>
                        <span><div></div></span>
                        <div className="landing_first">
                            <div className="landing_text">
                                <h2>BEAUTY AND ELEGANCE</h2>
                                <img alt='' src={transparentLogo}/>
                            </div>
                            
                            <div className="landing_buttons">
                                <Link to='/products' style={{textDecoration:'none'}}>
                                  <h4>Learn More</h4>
                               </Link>
                               <Link to='/contact-us' style={{textDecoration:'none'}}>
                                    <h4>Contact Us</h4>
                               </Link>
                            </div>
                        </div>
                    </li>   
                </ul>
               <svg className="Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1470 55"><path  fillOpacity="1" d="M0,0L48,16C96,32,192,64,288,74.7C384,85,480,75,576,74.7C672,75,768,85,864,74.7C960,64,1056,32,1152,37.3C1248,43,1344,85,1392,106.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
            </div>
        )
    }
}

export default LandingPage;