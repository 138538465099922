import React, { Component } from "react";

import LandingPage from "../LandingPage/LandingPage";
import AboutUs from "../AboutUs/AboutUs";
import WhyUs from "../WhyUs/WhyUs";
import WhatWeDo from "../WhatWeDo/WhatWeDo";
import ContactUs from "../ContactUS/ContactUS";
import Head from "../../hoc/Helmet/Helmet";
import axios from "axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHeart,
  faBriefcase,
  faUserShield,
  faSearchPlus,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHeart,
  faBriefcase,
  faMapMarkedAlt,
  faUserShield,
  faSearchPlus,
  faChevronLeft,
  faChevronRight,
  faTimes
);

class HomePage extends Component {
  state = {
    scrollPosition: null,
    wwdData: [],
  };

  //This helps to switch between dev and production

  componentDidMount() {
    console.log("About to run this");
    axios
      .get("/api/admin/getall")
      .then((res) => {
        this.setState({ wwdData: res.data });
      })
      .catch(console.log);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ scrollPosition: window.scrollY + window.innerHeight });
    this.debounce(this.checkSlide());
  };

  debounce(func, wait = 20, immediate = true) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  checkSlide(e) {
    let sliders = document.querySelectorAll(".slide_in");
    sliders.forEach((slider) => {
      const mainParent = slider.parentNode.parentNode;

      //half way through the image;
      const slideInAt =
        window.scrollY + window.innerHeight - slider.height + 50;
      const isFullShown = slideInAt > mainParent.offsetTop;

      if (isFullShown) {
        mainParent.classList.add("active");
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Head
          title="Paradiso Technologies Limited - Laser Cut Designs | Stainless and Wrought-Iron Rails Installations"
          description="Laser Cut Building addition such as Laser cut stair case rails, Laser cut balcony rails, Laser cut gates, Laser cut fence rails design and manufature, Stainless and Wrought-iron rails installation"
          tags="Laser, cut, Designs, Stainless, Wrought-iron, Building, Additions,Pergola, Balcony, Stair case,"
        />
        <LandingPage />

        <AboutUs scrollPosition={this.state.scrollPosition} />
        <WhyUs scrollPosition={this.state.scrollPosition} />
        <div className="app_wrapper">
          <WhatWeDo
            laser={this.state.wwdData?.laser_data}
            stainless={this.state.wwdData?.stainless_data}
            wrought={this.state.wwdData?.wrought_data}
            finished={this.state.wwdData?.finished_data}
          />
          <ContactUs />
        </div>
      </React.Fragment>
    );
  }
}

export default HomePage;
