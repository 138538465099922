import React from 'react'
import {NavLink} from 'react-router-dom'

import './NavigationItems.css'


const navigationItems = (props) => {
    return(

        <div className='navigation_items'>
            <NavLink to="/" exact className='navlink' activeClassName='active-class'> 
              <div onClick={props.navClicked}>
                  <h3 className='home'>Home</h3>  
              </div>    
            </NavLink>
                
            <NavLink to="/products" className='navlink' activeClassName='active-class' >  
              <div onClick={props.navClicked}>
                  <h3 className='products'>Products</h3>
              </div>
            </NavLink>


            <NavLink to="/completed-projects"  className='navlink' activeClassName='active-class'> 
               <div onClick={props.navClicked}>
                   <h3 className='finished'>Completed works</h3>
                </div> 
            </NavLink>

            <NavLink
            to="/contact-us" activeClassName='active-class' className='navlink' > 
            <div onClick={props.navClicked}>
                <h3 className='contactus'>Contact us</h3>
            </div>
           </NavLink>    
        </div>
   
    )
}

export default navigationItems;