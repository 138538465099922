import React from "react";
import { Link } from "react-router-dom";
import Lazyload from "react-lazy-load";
import axios from "axios";

import OurWork from "../../components/OurWork/OurWork";
import "./CompletedWorks.css";

class CompletedWorks extends React.Component {
  production = true;
  baseLink = this.production
    ? "https://node.paradisotechs.com"
    : "http://localhost:3001";

  state = {
    finished: [],
  };

  componentDidMount() {
    axios
      .get( "/api/admin/getFinishedProjects")
      .then((res) => {
        this.setState({ finished: res.data });
      })
      .then(() => {
        //This line is neccesary as the page refuses to load immediately
        let sliders = document.querySelectorAll(".slide_in");
        sliders.forEach((slider) => {
          const mainParent = slider.parentNode.parentNode;
          //half way through the image;
          mainParent.classList.add("active");
        });
      }).catch(console.log)
  }

  render = () => {
    return (
      <div className="completed-projects">
        <div className="ourWork_title">
          <h2>Projects</h2>
        </div>

        <div className="ourWork_message">
          <h2 className="others">We've completed a couple of projects:</h2>
          <h3>Here are a few of such elegant projects</h3>
        </div>
        <div className="ourWork_images">
          {this.state.finished.map((data) => {
            return (
              <Link to={`/building${data.id}/finished-products`} key={data.id}>
                <Lazyload key={data.id} debounce={false} offsetVertival={200}>
                  <OurWork
                    key={data.id}
                    image={data.image_url}
                    message={data.title}
                  />
                </Lazyload>
              </Link>
            );
          })}
        </div>
      </div>
    );
  };
}
export default CompletedWorks;
