import React, { Component } from "react";
import axios from "axios";

import WhatWeDo from "../WhatWeDo/WhatWeDo";
import "./Products.css";

class Products extends Component {
  state = {
    wwdData: [],
    laser: [],
    stainless: [],
    wrought: [],
    finished: [],
  };

  production = false;
  baseLink = this.production
    ? "https://node.paradisotechs.com"
    : "http://localhost:3001";

  componentDidMount() {
    axios
      .get("/api/admin/getall")
      .then((res) => {
        this.setState({
          laser: res.data?.laser_data,
          stainless: res.data?.stainless_data,
          wrought: res.data?.wrought_data,
          finished: res.data?.finished_data,
        });
      })
      .then(() => {
        //This line is neccesary as the page refuses to load immediately
        let sliders = document.querySelectorAll(".slide_in");
        sliders.forEach((slider) => {
          const mainParent = slider.parentNode.parentNode;
          //half way through the image;
          mainParent.classList.add("active");
        });
      });
  }

  componentDidUpdate() {
    let sliders = document.querySelectorAll(".slide_in");
    sliders.forEach((slider) => {
      const mainParent = slider.parentNode.parentNode;
      mainParent.classList.add("active");
    });
  }

  render() {
    return (
      <div className="Products">
        <WhatWeDo
          laser={this.state.laser}
          stainless={this.state.stainless}
          wrought={this.state.wrought}
          finished={this.state.finished}
        />
      </div>
    );
  }
}

export default Products;
