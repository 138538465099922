import React,{Component} from 'react';
import axios from 'axios'

import Input from '../../components/UI/Input/Input'
import SocialMedia from './SocialMedia/SocialMedia';
import './ContactUs.css'
import facebookIcon from '../../assets/icons/facebook.png';
import twitterIcon from '../../assets/icons/twitter.png';
import instagramIcon from '../../assets/icons/instagram.png';
import whatsappIcon from '../../assets/icons/whatsapp.png';
import Spinner from '../../components/Spinner/Spinner'
import emailImage from '../../assets/icons/email2.png'

class contactUS extends Component {

    state = {
        contactForm: {
            name: {
                elementType: 'input',
                elementConfig:{
                    type:'text',
                    placeholder: 'Your Name'
                },
                value: '',
                rules: {
                    required: true,
                    minLength: 3
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig:{
                    type:'email',
                    placeholder: 'Your Email'
                },
                value: '',
                rules: {
                    required: true,
                    minLength: 3
                },
                valid: false,
                touched: false
            },
            message: {
                elementType: 'textarea',
                elementConfig:{
                    rows: '5',
                    cols: '50',
                    placeholder: 'Enter Your Message Here'
                },
                value: '',
                rules: {
                    required: true,
                    minLength: 3
                },
                valid: false,
                touched: false
            }
        },

        showContactData: false,
        isSentFailed: false,
        isSentSuccessful: false,
        startedSending: false,
        isInvalidInput: false,
        invalidMessage: ''
    }

    shootUsHandler = () => {
        this.setState({showContactData: !this.state.showContactData})
    }

    resetField = () => {
        const fields = ['name', 'email', 'message']
        fields.forEach(field => {
           this.setState(prevState => ({
                ...prevState,
                contactForm:{
                    ...prevState.contactForm,
                    [field]:{
                        ...prevState.contactForm[field],
                        value: ''
                    }
                }
            
                })
            )  
        })
       

    }

    changeHandler = (event,id) => {
        console.log(event.target.value, id);
            const pressed = event.target.value
            this.setState(prevState => ({
                ...prevState,
                contactForm:{
                    ...prevState.contactForm,
                    [id]:{
                        ...prevState.contactForm[id],
                        value: pressed
                    }
                }
            
                })
            )
           
    }

    validateForm = (name, email, message) => {
        if(name.length < 3){
            return 'Enter your name please'
        } else if( message.length <3){
            return 'Enter a message in the message field'
        } else {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
             if(re.test(String(email).toLowerCase())){
                 return 'valid'
             }
             else {
                 return 'Enter a valid email address'
             }
           
        }
    }

    sendEmail = (event) => {
        event.preventDefault();

        const name = this.state.contactForm.name.value,
            email = this.state.contactForm.email.value,
            message = this.state.contactForm.message.value;


        const response = this.validateForm(name, email, message)
        if(response === 'valid') {
            this.setState({startedSending:true});
            axios.post('/api/admin/sendemail',{
                name: this.state.contactForm.name.value,
                email: this.state.contactForm.email.value,
                message: this.state.contactForm.message.value,
                imageUrl: ''
            })
            .then(res => {
                if(res.data === 'success'){
                    this.setState({isSentSuccessful: true, message:'', email:'', name:'',startedSending: false, isInvalidInput: false});
                    this.resetField();
                }
                else{
                    this.setState({isSentFailed: true,startedSending: false, isInvalidInput: false});
                }
            })
            .catch(err => {
                this.setState({isSentFailed: true,startedSending: false, isInvalidInput: false})
            })
        }
        else{
            this.setState({invalidMessage:response, isInvalidInput: true})
        }
        
    
        
    }

    render() {
        let formElementArray = [];

        for(let key in this.state.contactForm){
            formElementArray.push({
                id: key,
                config:this.state.contactForm[key]
            })
        }

        let contactDataForm = null;
        if(this.state.showContactData){
            contactDataForm = (
                <form>
                    {
                        formElementArray.map(formElement => {
                            return(
                                <Input 
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    key={formElement.id}
                                    invalid={!formElement.config.valid}
                                    shouldValidate={formElement.config.rules}
                                    touched={formElement.config.touched}
                                    changed={(event) => this.changeHandler(event,formElement.id)}
                                    />
                            )
                        })
                    }
                        {this.state.isInvalidInput? <h3 style={{color: 'red', textAlign:'center',margin: '5px'}}>{this.state.invalidMessage}</h3>: null}
                        {this.state.isSentFailed? <h3 style={{color: 'red', textAlign:'center',margin: '5px'}}>Sorry, Your email could not be sent</h3>: null}
                        {this.state.isSentSuccessful? <h3 style={{color: 'green', textAlign:'center',margin: '5px'}}>Your Email has been received</h3>: null}
                     {this.state.startedSending ? <Spinner color="green" textSize={14} spinnerSize = {40} message={'Sending'} /> : <button style={{cursor: 'pointer'}} onClick = {this.sendEmail}>Send Mesage</button>} 
                   
                </form> 
            )
        }

        let socialMedia = (
            <div className='social_media_wrapper'> 
                <SocialMedia link='https://www.facebook.com/paradisotechs' icon = {facebookIcon}/>
                <SocialMedia link='https://www.twitter.com/paradisotechs' icon = {twitterIcon} />
                <SocialMedia link='https://www.instagram.com/paradisotechs' icon = {instagramIcon} />
                <SocialMedia link="https://api.whatsapp.com/send?phone=2348137796116&text=I'd like to request your products and services" icon = {whatsappIcon} />

            </div>
        )

        return(
            <div>
                <div className='title contactUs_title'><h2>Contact Us</h2></div>
                <div className='contact_address'>
                    <h2>Contact Address:</h2>
                    <div className='inner_contact_address'>
                        <div className='second-contact'>
                            <div className='address'>
                            <h2>Head Office:</h2>
                            <h3> <i className='fa fa-map-marker-alt' > </i>  No 8 Arondizuogu street, Fegge, Onitsha</h3>
                            <h3>Anambra state, Nigeria.</h3>
                            </div>
                        
                            <div className='numbers'>
                                <h3> <i className='fa fa-phone' ></i> 07065071299, 08137796116</h3>
                            </div>
                        </div>
                        <div className='second-contact'>
                            <div className='address'>
                            <h2>Branch Office:</h2>
                            <h3> <i className='fa fa-map-marker-alt' > </i>  Shop 6, Corner Shopping Plaza, No 44,</h3>
                            <h3>Arthur Eze Avenue, Awka,</h3>
                            <h3>Anambra state, Nigeria.</h3>
                            </div>
                        
                            <div className='numbers'>
                                <h3> <i className='fa fa-phone' ></i> 07065071299, 08137796116</h3>
                            </div>
                        </div>


                    </div>
                    <a target='_blank' rel="noopener noreferrer" href="mailto:info@paradisotechs.com?subject=Request for Quotation"><img src={emailImage} alt='emimg' /> <h3>info@paradisotechs.com</h3></a>
                </div>
                <div className='contact_form_wrapper'>
                    {socialMedia}
                    <div className='name'><h4> <a target='_blank' rel="noopener noreferrer" href="mailto:info@paradisotechs.com?subject=Request for Quotation">Send Us a Mail</a></h4></div>
                    <div className='contact_form'>
                        {contactDataForm}
                    </div>
                </div>
            </div>
    )
    }
    
}

export default contactUS;