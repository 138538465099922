import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { RingLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Lazyload from "react-lazy-load";

import "./ViewMore.css";
import bigimage from "../../assets/images/gate design.jpeg";
import Backdrop from "../../hoc/Backdrop/Backdrop";
import zoomIcon from "../../assets/icons/zoom.png";

class ViewMore extends Component {
  state = {
    moreWwd: [],
    title: "",
    openingText: "",
    showOriginal: false,
    imageLoaded: false,
    orginalImageUrl: bigimage,
    currentId: "",
    isApiResponded: false,
    imageData: [],
    videoData: [],
  };

  originalTable = "";
  type = this.props.match.params.type;

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const type = this.props.match.params.type;
    let parentTable;
    switch (type) {
      case "stainless-rail-designs":
        {
          parentTable = "getstainless";
          this.originalTable = "getstainlessoriginal";
        }
        break;
      case "laser-cut-building-additions":
        {
          parentTable = "getwwd";
          this.originalTable = "getwwdoriginal";
        }
        break;
      case "wrought-iron-designs":
        {
          parentTable = "getwrought";
          this.originalTable = "getwroughtoriginal";
        }
        break;
      case "finished-products":
        {
          parentTable = "getfinishedProjects";
          this.originalTable = "getfinishedoriginal";
        }
        break;
    }

    axios
      .get("/api/admin/" + type + "/" + id)
      .then((res) => {
        if (res) {
          //Check if the response is finished product. Finished product would return an array at data[0]
          if (Array.isArray(res.data[0])) {
            this.setState({
              isApiResponded: true,
              imageData: res.data[0],
              videoData: res.data[1],
            });
          } else {
            this.setState({ isApiResponded: true, imageData: res.data });
          }
        }
      })
      .catch(console.log);

    const moreData = await axios.get(`/api/admin/${parentTable}more/${id}`);
    if (Array.isArray(moreData?.data?.[0])) {
      this.setState({
        isApiResponded: true,
        imageData: moreData.data[0],
        videoData: moreData.data[1],
      });
    } else {
      this.setState({
        isApiResponded: true,
        imageData: moreData.data,
      });
    }

    axios.get("/api/admin/" + parentTable + "/" + id).then((res) => {
      this.setState({
        title: res.data[0].title,
        openingText: res.data[0].opening_text,
      });
    });
  };

  handleMagnify = (id) => (event) => {
    this.setState({
      orginalImageUrl: "",
      showOriginal: true,
      imageLoaded: false,
      currentId: id,
    });
    axios.get("/api/admin/" + this.originalTable + "/" + id).then((res) => {
      this.setState({
        orginalImageUrl: res.data[0]?.image_url,
        imageLoaded: true,
      });
    });
  };

  cancleOriginal = () => {
    this.setState({ showOriginal: false });
  };

  nextOriginal = (dir) => (e) => {
    const currentId = this.state.currentId;
    let dataLength = this.state.imageData.length;
    let data = [...this.state.imageData];
    dataLength--;
    let mainId = null;
    let index = 0;

    data.forEach((data, i) => {
      if (data.id === currentId) {
        //pick the next index
        if (dir === "next") {
          if (i < dataLength) {
            index = i + 1;
          } else {
            index = 0;
          }
        }
        //pick the prev index
        else {
          if (i > 0) {
            index = i - 1;
          } else {
            index = dataLength;
          }
        }
      } else {
      }
    });

    this.state.imageData.forEach((data, i) => {
      if (i === index) {
        mainId = data.id;
      }
    });

    this.setState({
      orginalImageUrl: "",
      showOriginal: true,
      imageLoaded: false,
      currentId: mainId,
    });
    axios.get("/api/admin/" + this.originalTable + "/" + mainId).then((res) => {
      if (Array.isArray(res.data[0])) {
        this.setState({
          orginalImageUrl: res.data[0][0].image_url,
          imageLoaded: true,
        });
      } else {
        this.setState({
          orginalImageUrl: res.data[0].image_url,
          imageLoaded: true,
        });
      }
    });
  };

  render() {
    const wholePage = (
      <React.Fragment>
        {this.state.showOriginal ? (
          <div className="original-image">
            {this.state.imageLoaded ? (
              <img src={this.state.orginalImageUrl} alt="" />
            ) : (
              <RingLoader
                className={"spinner"}
                size={50}
                sizeUnit={"px"}
                color={"rgb(250, 50, 50)"}
                loading={true}
                style={{
                  display: "block",
                  margin: "auto",
                  borderColor: "red",
                }}
              />
            )}
            <div className={"controls"}>
              <FontAwesomeIcon
                onClick={this.nextOriginal("prev")}
                className="icon"
                icon="chevron-left"
                size="1x"
              />
              <FontAwesomeIcon
                onClick={this.nextOriginal("next")}
                className="icon"
                icon="chevron-right"
              />
            </div>
            <div className="cancel">
              <FontAwesomeIcon
                onClick={this.cancleOriginal}
                className="icon"
                icon="times"
              />
            </div>
            <div className="request">
              <Link
                style={{ textDecoration: "none" }}
                to={`/request/${this.type}/${this.state.currentId}`}
              >
                <h3>Request</h3>
              </Link>
            </div>
          </div>
        ) : null}

        <div className="texts">
          <h1>{this.state.title}</h1>
          <h3>{this.state.openingText}</h3>
        </div>
        <div className="row">
          {this.state.imageData.map((data) => {
            return (
              <Lazyload key={data.id} debounce={false} offsetVertical={100}>
                <div
                  onClick={this.handleMagnify(data.id)}
                  key={data.id}
                  className="image"
                >
                  <img src={data.image_url} alt="Laser Cut Designs" />
                  <div className="smallBackdrop"></div>
                  <div className="controls">
                    <div className="zoomIcon">
                      <img
                        onClick={this.handleMagnify(data.id)}
                        src={zoomIcon}
                        alt="Laser Cut Zooms"
                      />
                    </div>
                    <Link
                      className="link"
                      style={{ textDecoration: "none" }}
                      to={`/request/${this.type}/${data.id}`}
                    >
                      <h3>Request</h3>
                    </Link>
                  </div>
                </div>
              </Lazyload>
            );
          })}
        </div>

        <div className="row">
          {this.state.videoData.map((data) => {
            return (
              <Lazyload key={data.id} debounce={false} offsetVertical={100}>
                <div key={data.id} className="video">
                  <iframe src={data.video_url}></iframe>
                </div>
              </Lazyload>
            );
          })}
        </div>
      </React.Fragment>
    );

    const spinner = (
      <div className="outerSpinner">
        <RingLoader
          className={"spinner"}
          size={120}
          sizeUnit={"px"}
          color={"#98bdd8"}
          loading={true}
          style={{
            display: "block",
            borderColor: "red",
            margin: "40px",
          }}
        />
        <h2>Wait a second, Page is Loading ....</h2>
      </div>
    );
    return (
      <div className="ViewMore">
        <Backdrop
          backdropClicked={this.cancleOriginal}
          show={this.state.showOriginal}
        />
        {this.state.isApiResponded ? wholePage : spinner}
      </div>
    );
  }
}

export default ViewMore;
