import React from "react";
import Spinner from "../../components/Spinner/Spinner";

import "./AdminWWA.css";

const adminWWA = (props) => {
  return (
    <div className="AdminWWA">
      <div className="sample">
        <h5>{props.wwaText}</h5>
        <img alt="" src={props.wwaImageUrl} />
      </div>
      <form>
        <div>
          <textarea
            rows="7"
            cols="50"
            className="textArea"
            onChange={props.textChangeHandler}
            required
          ></textarea>
          <input
            className="file_input"
            type="file"
            accept="image/*"
            onChange={props.fileSelectedHandler}
            required
          />
        </div>
        {props.loading ? (
          <Spinner message="Uploading" spinnerSize={50} messageSize={16} />
        ) : (
          <input
            className="submit"
            type="submit"
            value="Upload"
            onClick={props.onUploadHandler}
          />
        )}
      </form>
    </div>
  );
};

export default adminWWA;
