import React, {Component} from 'react';

import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import './Layout.css'

class Layout extends Component {
    state = {
        openMenu: false
    }

    menuClicked = () =>{
        const openState = this.state.openMenu;
        this.setState({openMenu:!openState});
    }
    render() {
        return(
            <div className='layout'>
                <div className='fixed_components'>
                    <Toolbar 
                        openMenu={this.state.openMenu}
                        menuClicked={this.menuClicked} />
                    <SideDrawer
                        backdropClicked = {this.menuClicked}
                        openMenu={this.state.openMenu} />
                </div>
                
                <main className='children'>
                     {this.props.children}
                </main>
                <footer>
                    <div className='footer'>
                       <h4 className='futer_h4'>Copyright {new Date().getUTCFullYear()}, Paradiso Technologies Limited | all rights reserved</h4> 
                    </div>
                </footer>
               
            </div>
            )
    }
}

export default Layout;