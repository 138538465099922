import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems'
import Backdrop from '../../../hoc/Backdrop/Backdrop';
import './SideDrawer.css';


const sideDrawer= (props) => {
    let drawerClass = props.openMenu? ['side_drawer','open']: ['side_drawer','close']

    return (
        <React.Fragment>
            <Backdrop 
                show={props.openMenu}
                backdropClicked={props.backdropClicked}/>
            <div className= {drawerClass.join(' ')}>
                <NavigationItems navClicked = {props.backdropClicked}/>
            </div>
        </React.Fragment>
        
    )
}

export default sideDrawer;