import React, { Component } from "react";
import axios from "axios";
import { RingLoader } from "react-spinners";

import "./Request.css";
import whatsappIcon from "../../assets/icons/whatsapp.png";
import emailIcon from "../../assets/icons/email2.png";

class Request extends Component {
  state = {
    imageUrl: "",
    showEmail: false,
    name: "",
    email: "",
    message: "",
    isSentFailed: false,
    isSentSuccessful: false,
    startedSending: false,
  };

  production = false;
  baseLink = this.production
    ? "https://node.paradisotechs.com"
    : "http://localhost:3001";
  originalTable = "";
  componentDidMount = () => {
    const id = this.props.match.params.id;
    const type = this.props.match.params.type;

    switch (type) {
      case "stainless-rail-designs":
        this.originalTable = "getstainlessoriginal";

        break;
      case "laser-cut-building-additions":
        this.originalTable = "getwwdoriginal";

        break;
      case "wrought-iron-designs":
        this.originalTable = "getwroughtoriginal";

        break;
      case "finished-products":
        this.originalTable = "getfinishedoriginal";

        break;

      default:
        break;
    }

    axios
      .get("/api/admin/" + this.originalTable + "/" + id)
      .then((res) => {
        this.setState({ imageUrl: res.data[0].image_url });
      })
      .catch((err) => {
        console.log("we could fetch request");
      });
  };

  openEmail = () => {
    this.setState({ showEmail: !this.state.showEmail });
  };

  nameChanged = (event) => {
    this.setState({ name: event.target.value });
  };

  emailChanged = (event) => {
    this.setState({ email: event.target.value });
  };

  messageChanged = (event) => {
    this.setState({ message: event.target.value });
  };

  sendEmail = (event) => {
    event.preventDefault();
    this.setState({ startedSending: true });
    axios
      .post("/api/admin/sendemail", {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        imageUrl: this.state.imageUrl,
      })
      .then((res) => {
        if (res.data === "success") {
          this.setState({
            isSentSuccessful: true,
            message: "",
            email: "",
            name: "",
          });
          this.setState({ startedSending: false });
        } else {
          this.setState({ isSentFailed: true });
          this.setState({ startedSending: false });
        }
      })
      .catch((err) => {
        this.setState({ isSentFailed: true });
        this.setState({ startedSending: false });
      });
  };

  handleOkButton = () => {
    this.setState({ isSentFailed: false, isSentSuccessful: false });
  };

  render() {
    const sending = (
      <div>
        <h3>Sendng...</h3>
        <RingLoader
          className={"spinner"}
          size={30}
          sizeUnit={"px"}
          color={"red"}
          loading={true}
          style={{
            display: "block",
            margin: "auto",
            borderColor: "red",
          }}
        />
      </div>
    );

    return (
      <div className="Request">
        {this.state.isSentSuccessful ? (
          <div className="message">
            <h3>Your message was sent succesfully.</h3>
            <button onClick={this.handleOkButton}>Ok</button>
          </div>
        ) : null}
        {this.state.isSentFailed ? (
          <div className="message">
            <h3>
              Your message was NOT sent. You can retry or use the whatsapp
              option
            </h3>
            <button onClick={this.handleOkButton}>Ok</button>
          </div>
        ) : null}

        <div className="image">
          <img alt="" src={this.state.imageUrl} />
        </div>
        <div className="controls">
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            href={
              "https://api.whatsapp.com/send?phone=2347065071299&text=I'd like to request this product: " +
              this.state.imageUrl
            }
          >
            <img src={whatsappIcon} alt="" />
            <h3>Request Via Whatsapp</h3>
          </a>
          <a
            className="email"
            target="_blank"
            rel="noopener noreferrer"
            href={
              "mailto:info@paradisotechs.com?subject=Request for Quotation&body= I'd like to request this product: " +
              this.state.imageUrl
            }
          >
            <img src={emailIcon} alt="" />
            <h3> Request Via Email</h3>
          </a>
        </div>
        {this.state.showEmail ? (
          <div className="form">
            <form onSubmit={this.sendEmail}>
              <input
                onChange={this.nameChanged}
                type="text"
                required
                placeholder="Your Name;"
              />
              <input
                onChange={this.emailChanged}
                type="email"
                placeholder="Your Email Address"
              />
              <input
                onChange={this.messageChanged}
                type="Message"
                placeholder="Enter additional message (Optional)"
              />
              <div>
                {this.state.startedSending ? sending : <input type="submit" />}
              </div>
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Request;
