import React from 'react';
import './AdminWWD.css';
import Spinner from '../../components/Spinner/Spinner'

const adminWWD = (props) => {
    
    
    return (
        <div className="AdminWWD">

            <div className="edit_bar"
                style={{display: props.showEdit? 'flex':'none'}}>
                <div className="input_fields">
                    <input type="text" value = {props.editTitle} onChange= {props.editTextHandler} />
                    <textarea rows='6' value = {props.editOpeningText} placeholder="Opening message" cols='40' onChange={props.openingTextHandler}></textarea>
                    <input type="file" onChange = {props.editFileHandler} /> 
                </div>
               <div className="input_controls">
                   <button onClick={props.editUpdateHandler('laser')}>Update</button>
                    <button onClick={props.editCancelHandler}>Cancel</button>
               </div>
                
            </div>
            <div className ="sample">
                <table rules="groups">
                   <thead>
                        <tr>
                            <th>Opening Message </th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Add More</th>
                            <th>Edit</th>
                            <th>Delete</th> 
                        </tr>
                   </thead> 
                        
                  
                    <tbody>
                         {props.wwdData.map(data =>{
                             
                            return(
                                    <tr key={data.id} className = "table_row" >
                                        <td>{data.opening_text.length > 100? data.opening_text.substring(0,60)+"...": data.opening_text}</td> 
                                        <td>{data.title}</td>
                                        <td><img alt=""  src={data.image_url} width ="100px" /></td>
                                        <td><button onClick = {props.handleAddMore(data.id,'laser')}>Add More</button></td>
                                        <td><button onClick = {props.handleEdit(data.id)}>Edit</button></td>
                                        <td>{ props.loading? 
                                                ( data.id === props.currentId ? 
                                                    <Spinner message={'deleting'} spinnerSize={30} textSize={13} />
                                                    : 
                                                    <button   onClick = {props.handleDelete(data.id,'parent','laser')}>Delete</button> 
                                                    )
                                                : 
                                                <button   onClick = {props.handleDelete(data.id,'parent','laser')}>Delete</button> }</td>
                                   
                                    </tr>
                            )
                        
                        })
                        } 
                    </tbody>
                   
                </table>
            </div>

            <form>
                <div>
                    <input type='text' placeholder ="Title" onChange={props.textChangeHandler} required />
                    <textarea rows='6' placeholder="Opening message" cols='40' onChange={props.openingTextHandler}></textarea>
                    <input className = 'file_input' type ="file" accept ="image/*" onChange={props.fileSelectedHandler}  required/>   
                </div>
                {props.loading ? <Spinner  message='Uploading' spinnerSize={50} messageSize={16} /> : <input className='submit' type = "submit" value="Upload" onClick={props.onUploadHandler('addwwd')} /> }
            </form>
        </div>
    )
}

export default adminWWD;